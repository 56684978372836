<template>
  <rs-row>
    <rs-col :lg="4" :md="6" :sm="8" align="center">
      <rs-form :model="loginData" ref="Form" @submit.native="submit">
        <div class="card card-login">
          <div class="card-header card-header-rose text-center">
            <h4 class="card-title">Login</h4>
          </div>
          <div class="card-body">
            <rs-form-item label="用户名" prop="username" :rules="[{required: true,message: '用户名必填'}]">
              <rs-input v-model="loginData.username"></rs-input>
            </rs-form-item>
            <rs-form-item label="密码" prop="password" :rules="[{required: true,message: '密码必填'}]">
              <rs-input v-model="loginData.password" type="password"></rs-input>
            </rs-form-item>
            <rs-form-item>
              <rs-button class="justify-content-center" type="submit" color="rose">登录</rs-button>
            </rs-form-item>
          </div>
        </div>
      </rs-form>
    </rs-col>
  </rs-row>
</template>

<script>
import { mapMutations } from 'vuex'
import sha1 from 'js-sha1'

export default {
  name: 'Home',
  data () {
    return {
      loginData: { username: '', password: '' }
    }
  },
  methods: {
    ...mapMutations(['changeLogin']),
    submit (e) {
      this.$refs.Form.validate(isValidate => {
        if (isValidate) {
          // 账号密码 请求token
          this.$api.user.login({ username: this.loginData.username, password: sha1(this.loginData.password) },
            (data) => {
              this.changeLogin({ Authorization: data.data.Authorization })
              this.$router.push('/')
            }
          )
        } else {
          console.log('请正确输入')
        }
        e.preventDefault()
      })
    }
  }
}
</script>
